import React from "react"
import sanitizeHtml from 'sanitize-html';

const defaultOptions = {
  allowedTags: [ 'p', 'b', 'i', 'em', 'strong', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li' ],
  allowedAttributes: {
    'a': [ 'href' ]
  },
};

const sanitize = (dirty, options) => ({
  __html: sanitizeHtml(
    dirty,
    { ...defaultOptions, ...options }
  )
});

const RenderHTML = ({ html, options }) => {
  return (
    <div dangerouslySetInnerHTML={sanitize(html, options)} />
  )
}

export default RenderHTML
